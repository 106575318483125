.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
    color: #3e497a;
  }
  
  .me {
    width: 20rem;
    min-height: 17rem;
    margin: auto;
    clip-path: circle();
    background-color: #192543;
    margin-bottom: -5rem;
  }

  .resume-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    background-color: #3e497a;
    color: #f0f0f0;
    border: none;
    border-radius: 5rem;
    font-size: 0.5rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .resume-button:hover {
    background-color: #21325e;
  }
  
  .resume-icon {
    font-size: 1.2rem;
  }
  
  .resume-text {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .about {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #21325e;
    color: #f0f0f0;
  }
  
  .about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 80px;
    color: #e9d35b;
    height: 50px;
  }
  
  .about .prompt {
    width: 40%;
    font-size: 30px;
  }
  
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
  }
  
  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    padding: 0 2rem;
    box-sizing: border-box;
  }
  
  .skills h1 {
    color: #3e497a;
    margin-top: 0;
  }
  
  .skills .list {
    list-style: none;
    width: 100%;
  }
  
  .skills .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .list .item {
    background-color: #f0f0f0;
    padding: 2rem;
    border-radius: 5px;
    text-align: center;
    border: 2px solid #3e497a;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .list .item:hover {
    transform: scale(1.05);
  }
  
  .list .item h1 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  
  .list .item span {
    font-size: 18px;
  }
  
  @media only screen and (max-width: 900px) {
    .about h2 {
      padding-top: 1rem;
      font-size: 35px;
      margin-top: 2rem;
      margin-bottom: 0;
    }
    
    .about .prompt {
      margin-top: 10px;
      font-size: 20px;
      width: 80%;
    }
    
    .skills {
      text-align: center;
    }
    
    .skills h1 {
      font-size: 30px;
    }
    
    .skills .list {
      grid-template-columns: 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) and (min-width: 500px) {
    .skills .row {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 2rem;
    }
    
    .list .item {
      width: calc(100% - 4rem);
      max-width: 100%;
      margin: 0 auto;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .skills .row {
      display: flex;
      overflow-x: scroll; /* Always show scrollbar */
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      gap: 1rem;
      margin-bottom: 2rem;
    }
    
    .list .item {
      flex: 0 0 80%;
      min-width: 250px;
      scroll-snap-align: start;
      margin: 0 1rem;
    }
    
    .list .item:last-child {
      margin-right: 1rem;
    }
    
    .skills {
      padding: 0 1rem;
    }
    
    /* Custom scrollbar styles */
    .skills .row::-webkit-scrollbar {
      height: 8px;
    }
    
    .skills .row::-webkit-scrollbar-thumb {
      background-color: #3e497a;
      border-radius: 4px;
    }
    
    .skills .row::-webkit-scrollbar-track {
      background-color: #f0f0f0;
    }
  }
  
  .view-projects {
    text-align: center;
    margin-top: 3rem;
  }
  
  .view-projects h1 {
    color: #3e497a;
    font-size: 35px;
    margin-top: 0;
  }
  
  .view-projects p {
    font-size: 18px;
    margin-bottom: 2rem;
  }
  
  .view-projects-button {
    display: inline-block;
    padding: 0.8rem 2rem;
    background-color: #3e497a;
    color: #f0f0f0;
    text-decoration: none;
    border-radius: 5px;
    font-size: 20px;
    transition: background-color 0.3s ease;
  }
  
  .view-projects-button:hover {
    background-color: #21325e;
  }
  
  .arrow-icon {
    font-size: 20px;
    margin-left: 5px;
  }