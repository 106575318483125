.projects {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .projects .projectTitle {
    text-align: center;
    color: #3e497a;
    font-size: 60px;
    font-weight: bolder;
  }
  
  .projectList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }
  
  /* MENU ITEM STYLING */
  
  .projectItem {
    border-radius: 15px;
    width: 300px;
    height: 300px;
    margin: 40px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  .projectItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  .projectItem .bgImage {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 300px;
    border-radius: 10px;
  }
  
  .projectItem h1 {
    margin-top: 10px;
    text-align: center;
    color: #3e497a;
  }
  
  
  @media only screen and (max-width: 1300px) {
    .projectList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      grid-template-columns: 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      width: 100%;
    }
    .projectItem {
      width: 300px;
      height: 300px;
    }
  }